import React from 'react';
import PropTypes from 'prop-types';
import ScrollReveal from 'scrollreveal';

const animations = {
  slideUp: {
    distance: '100%',
    origin: 'bottom',
    duration: 1000
  },
  fadeIn: {
    opacity: 0,
    duration: 1000
  },
  expand: {
    opacity: 1,
    scale: 0.95,
    duration: 1500
  },
  slideFromLeft: {
    origin: 'left',
    distance: '1500px',
    duration: 2000
  },
  slideFromRight: {
    origin: 'right',
    distance: '1500px',
    duration: 2000
  }
};

class ScrollRevealWrapper extends React.Component {
  componentDidMount() {
    const { animation, animationOptions } = this.props;
    ScrollReveal().reveal(`.sr__reveal--${animation}`, Object.assign(animations[animation], animationOptions));
  }

  render() {
    const { children, animation } = this.props;
    return <div className={`sr__hidden-on-initial-paint sr__reveal--${animation}`}>{children}</div>;
  }
}

ScrollRevealWrapper.propTypes = {
  animation: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  animationOptions: PropTypes.shape({
    mobile: PropTypes.bool
  })
};

ScrollRevealWrapper.defaultProps = {
  animation: 'slideUp',
  animationOptions: {}
};

export default ScrollRevealWrapper;
